import React, { useCallback } from 'react'
import {
  AddressBlock,
  Container,
  Paragraph,
  StrongText,
  LanguageSelector,
  FooterStyles,
  CopyRightsFooter
} from './styles'
//import { LegalTermsOfServices } from '../../utils/constants'
// eslint-disable-next-line no-restricted-imports
import { Footer } from '@veneer/core'
import { MicroFrontend } from '@jarvis/react-mfe-component'
import useRootContext from '../../contexts/Root/useRootContext'

export type LegalTermsOfServiceProps = {
  country?: string
  language?: string
}

const LegalTermsOfService = (props) => {
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()
  const LanguageSelectorMfe = useCallback((type) => {
    return (
      <MicroFrontend
        type={type}
        component={'@jarvis/react-ecp-language-selector-widget'}
      />
    )
  }, [])
  return (
    <>
      <Container>
        <div>
          <h4 data-testid="terms-of-service-title">
            {t('user-legal-terms-of-service.title')}
          </h4>
          <h1 data-testid="terms-of-service-subTitle">
            {t('user-legal-terms-of-service.subTitle')}
          </h1>
          <h3 data-testid="terms-of-service-header">
            {t('user-legal-terms-of-service.header')}
          </h3>
          <Paragraph data-testid="terms-of-service-headerDescription">
            {t('user-legal-terms-of-service.headerDescription')}
            <a
              data-testid="terms-of-service-link"
              href="https://www.hp.com/us-en/privacy/privacy.html"
              target="_blank"
              rel="noreferrer"
            >
              {t('user-legal-terms-of-service.link')}
            </a>
            <span data-testid="terms-of-service-extText">
              {t('user-legal-terms-of-service.extText')}
            </span>
            <StrongText data-testid="terms-of-service-terms">
              {t('user-legal-terms-of-service.terms')}
            </StrongText>
            <span data-testid="terms-of-service-para1">
              {t('user-legal-terms-of-service.para1')}
            </span>
            <StrongText data-testid="terms-of-service-you">
              {t('user-legal-terms-of-service.you')}
            </StrongText>
            <span data-testid="terms-of-service-para2">
              {t('user-legal-terms-of-service.para2')}
            </span>
          </Paragraph>
          <ul data-testid="terms-of-service-title">
            <li data-testid="terms-of-service-list-1">
              <StrongText data-testid="terms-of-service-title">
                {t('user-legal-terms-of-service.definition.title')}
              </StrongText>
              <ul data-testid="terms-of-service-accountTitle">
                <li>
                  <StrongText data-testid="terms-of-service-accountTitle">
                    {t('user-legal-terms-of-service.definition.accountTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-account">
                    {t('user-legal-terms-of-service.definition.account')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-additionalTitle">
                    {t(
                      'user-legal-terms-of-service.definition.additionalTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-additionalServices">
                    {t(
                      'user-legal-terms-of-service.definition.additionalServices'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-connectedDevicesTitle">
                    {t(
                      'user-legal-terms-of-service.definition.connectedDevicesTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-connectedDevices">
                    {t(
                      'user-legal-terms-of-service.definition.connectedDevices'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-contentTitle">
                    {t('user-legal-terms-of-service.definition.contentTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-content">
                    {t('user-legal-terms-of-service.definition.content')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-paymentTitle">
                    {t('user-legal-terms-of-service.definition.paymentTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-payment-processor">
                    {t(
                      'user-legal-terms-of-service.definition.paymentProcessor'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-platformORcommandCenterTitle">
                    {t(
                      'user-legal-terms-of-service.definition.platformORcommandCenterTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-platformORcommandCenter">
                    {t(
                      'user-legal-terms-of-service.definition.platformORcommandCenter'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-portfolioTitle">
                    {t('user-legal-terms-of-service.definition.portfolioTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-portfolio">
                    {t('user-legal-terms-of-service.definition.portfolio')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-servicesTitle">
                    {t('user-legal-terms-of-service.definition.servicesTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-services">
                    {t('user-legal-terms-of-service.definition.services')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-solutionsTitle">
                    {t('user-legal-terms-of-service.definition.solutionsTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-solutions">
                    {t('user-legal-terms-of-service.definition.solutions')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-thirdpartyTitle">
                    {t(
                      'user-legal-terms-of-service.definition.thirdpartyTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-thirdPartyProducts">
                    {t(
                      'user-legal-terms-of-service.definition.thirdPartyProducts'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-userDataTitle">
                    {t('user-legal-terms-of-service.definition.userDataTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-userDataDefinition">
                    {t(
                      'user-legal-terms-of-service.definition.userData.userDataDefinition'
                    )}
                  </span>
                  <ul>
                    <li data-testid="terms-of-service-userData1">
                      {t('user-legal-terms-of-service.definition.userData.1')}
                    </li>
                    <li data-testid="terms-of-service-userData2">
                      {t('user-legal-terms-of-service.definition.userData.2')}
                    </li>
                    <li data-testid="terms-of-service-userData3">
                      {t('user-legal-terms-of-service.definition.userData.3')}
                    </li>
                    <li data-testid="terms-of-service-userData4">
                      {t('user-legal-terms-of-service.definition.userData.4')}
                    </li>
                    <li data-testid="terms-of-service-userData5">
                      {t('user-legal-terms-of-service.definition.userData.5')}
                    </li>
                    <li data-testid="terms-of-service-userData6">
                      {t('user-legal-terms-of-service.definition.userData.6')}
                    </li>
                    <li data-testid="terms-of-service-userData7">
                      {t('user-legal-terms-of-service.definition.userData.7')}
                    </li>
                    <li data-testid="terms-of-service-userData8">
                      {t('user-legal-terms-of-service.definition.userData.8')}
                    </li>
                    <li data-testid="terms-of-service-userData9">
                      {t('user-legal-terms-of-service.definition.userData.9')}
                    </li>
                    <li data-testid="terms-of-service-userData10">
                      {t('user-legal-terms-of-service.definition.userData.10')}
                    </li>
                  </ul>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-we">
                    {t('user-legal-terms-of-service.definition.we')}
                  </StrongText>
                  <span data-testid="terms-of-service-weUsHpOur">
                    {t(
                      'user-legal-terms-of-service.definition.userData.weUsHpOur'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-yourCustomer">
                    {t('user-legal-terms-of-service.definition.yourCustomer')}
                  </StrongText>
                  <span data-testid="terms-of-service-youYourCustomer">
                    {t(
                      'user-legal-terms-of-service.definition.userData.youYourCustomer'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-2">
              <StrongText data-testid="terms-of-service-servicestitle">
                {t('user-legal-terms-of-service.services.title')}
              </StrongText>
              <ul>
                <li>
                  <StrongText data-testid="terms-of-service-registrationTitle">
                    {t(
                      'user-legal-terms-of-service.services.registrationTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-registration">
                    {t('user-legal-terms-of-service.services.registration')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-networkTitle">
                    {t('user-legal-terms-of-service.services.networkTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-networkEnvironment">
                    {t(
                      'user-legal-terms-of-service.services.networkEnvironment'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-chargesTitle">
                    {t('user-legal-terms-of-service.services.chargesTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-charges">
                    {t('user-legal-terms-of-service.services.charges')}
                  </span>
                </li>
                <li data-testid="terms-of-service-services4">
                  {t('user-legal-terms-of-service.services.services4')}
                </li>
                <li data-testid="terms-of-service-services5">
                  {t('user-legal-terms-of-service.services.services5')}
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-3">
              <StrongText data-testid="terms-of-service-registrationAndUserAccount">
                {t(
                  'user-legal-terms-of-service.registrationAndUserAccount.title'
                )}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li>
                  <StrongText data-testid="terms-of-service-establishingAnAccountTitle">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.establishingAnAccountTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-establishingAnAccount">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.establishingAnAccount'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-accountInformationTitle">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.accountInformationTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-accountInformation">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.accountInformation'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-administratorTitle">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administratorTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-administrator1">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administrator1'
                    )}
                  </span>
                  <StrongText data-testid="terms-of-service-administrator2">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administrator2'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-administrator3">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administrator3'
                    )}
                  </span>
                  <StrongText data-testid="terms-of-service-administrator4">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administrator4'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-administrator5">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.Administrator.administrator5'
                    )}
                  </span>
                </li>
                <li data-testid="terms-of-service-registrationAndUserAccount-list">
                  <StrongText data-testid="terms-of-service-accessRightsTitle">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.accessRightsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-accessrights">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.accessRights'
                    )}
                  </span>
                </li>
                <li data-testid="terms-of-service-deletion">
                  <StrongText data-testid="terms-of-service-deletionOfAccountTitle">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.deletionOfAccountTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-deletionOfAccount">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.deletionOfAccount'
                    )}
                  </span>
                </li>
                <li data-testid="terms-of-service-registrationAndUserAccount">
                  <StrongText data-testid="terms-of-service-useOfData">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData1'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-useOfData1">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData.1'
                    )}
                  </span>
                  <br /> <br />
                  <span data-testid="terms-of-service-useOfData2">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData.2'
                    )}
                  </span>
                  <br /> <br />
                  <span data-testid="terms-of-service-useOfData3">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData.3'
                    )}
                  </span>
                  <br /> <br />
                  <span data-testid="terms-of-service-useOfData4">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData.4'
                    )}
                  </span>
                  <a
                    href="https://www.hp.com/in-en/privacy/privacy-central.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span data-testid="terms-of-service-privacy">
                      {
                        (t(
                          'user-legal-terms-of-service.registrationAndUserAccount.useOfData.privacy'
                        ),
                        'www.hp.com/go/privacy.')
                      }
                    </span>
                  </a>
                  <br /> <br />
                  <span data-testid="terms-of-service-useOfData5">
                    {t(
                      'user-legal-terms-of-service.registrationAndUserAccount.useOfData.5'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-4">
              <StrongText data-testid="terms-of-service-yourcontent-title">
                {t('user-legal-terms-of-service.yourContent.title')}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li>
                  <StrongText data-testid="terms-of-service-licenseToContentTitle">
                    {t(
                      'user-legal-terms-of-service.yourContent.licenseToContentTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-licenseToContent">
                    {t(
                      'user-legal-terms-of-service.yourContent.licenseToContent'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-responsibilityForContentTitle">
                    {t(
                      'user-legal-terms-of-service.yourContent.responsibilityForContentTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-responsibilityForContent">
                    {t(
                      'user-legal-terms-of-service.yourContent.responsibilityForContent'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-5">
              <StrongText data-testid="terms-of-service-otherproductsandservices-title">
                {t(
                  'user-legal-terms-of-service.otherProductsAndServices.title'
                )}
              </StrongText>
              <br />
              <span data-testid="terms-of-service-description">
                {t(
                  'user-legal-terms-of-service.otherProductsAndServices.description'
                )}
              </span>
            </li>
            <li data-testid="terms-of-service-list-6">
              <StrongText data-testid="terms-of-service-feesandpayment-title">
                {t('user-legal-terms-of-service.feesAndPayment.title')}
              </StrongText>
              <ul>
                <li>
                  <StrongText data-testid="terms-of-service-feesandpayment">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.paymentTermsforAdditionalServices.title'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-feesandpayment-1">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.paymentTermsforAdditionalServices.1'
                    )}
                  </span>
                  <br />
                  <span data-testid="terms-of-service-feesandpayment-2">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.paymentTermsforAdditionalServices.2'
                    )}
                  </span>
                  <br />
                  <span data-testid="terms-of-service-feesandpayment-3">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.paymentTermsforAdditionalServices.3'
                    )}
                  </span>
                  <br />
                  <span data-testid="terms-of-service-feesandpayment-4">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.paymentTermsforAdditionalServices.4'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-feesandpayment-charges">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.feeCharges.title'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-feeCharges">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.feeCharges.1'
                    )}
                  </span>
                  <br />
                  <span data-testid="terms-of-service-feeCharges-1">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.feeCharges.2'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-billing-title">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.billingInformationTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-billing-information">
                    {t(
                      'user-legal-terms-of-service.feesAndPayment.billingInformation'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-taxes-title">
                    {t('user-legal-terms-of-service.feesAndPayment.taxesTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-taxes">
                    {t('user-legal-terms-of-service.feesAndPayment.taxes')}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-7">
              <StrongText data-testid="terms-of-service-restrictions-title">
                {t(
                  'user-legal-terms-of-service.useObligationsAndRestrictions.title'
                )}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li>
                  <StrongText data-testid="terms-of-service-restrictionsobligations-title">
                    {t(
                      'user-legal-terms-of-service.useObligationsAndRestrictions.obligationsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-obligations">
                    {t(
                      'user-legal-terms-of-service.useObligationsAndRestrictions.obligations'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-restrictions-obligations-title">
                    {t(
                      'user-legal-terms-of-service.useObligationsAndRestrictions.restrictionsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-restrictions">
                    {t(
                      'user-legal-terms-of-service.useObligationsAndRestrictions.restrictions'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-8">
              <StrongText data-testid="terms-of-service-intellectual-title">
                {t(
                  'user-legal-terms-of-service.intellectualPropertyRights.title'
                )}
              </StrongText>
              <ul>
                <li>
                  <StrongText data-testid="terms-of-service-retention">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.retentionOfRightsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-retention-rights">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.retentionOfRights'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-feedback-title">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.feedbackTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-feedback1">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.feedback1'
                    )}
                  </span>
                  <StrongText data-testid="terms-of-service-feedback">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.feedback'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-feedback2">
                    {t(
                      'user-legal-terms-of-service.intellectualPropertyRights.feedback2'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-9">
              <StrongText data-testid="terms-of-service-indemnification-title">
                {t('user-legal-terms-of-service.indemnification.title')}
              </StrongText>
              <br />
              <span data-testid="terms-of-service-indemnificationpara1">
                {t(
                  'user-legal-terms-of-service.indemnification.indemnificationpara1'
                )}
              </span>
              <StrongText data-testid="terms-of-service-indemnificationpara2">
                {t(
                  'user-legal-terms-of-service.indemnification.indemnificationpara2'
                )}
              </StrongText>
              <span data-testid="terms-of-service-indemnificationpara3">
                {t(
                  'user-legal-terms-of-service.indemnification.indemnificationpara3'
                )}
              </span>
            </li>
            <li data-testid="terms-of-service-list-10">
              <StrongText data-testid="terms-of-service-disclaimer-title">
                {t('user-legal-terms-of-service.disclaimersOfWarranties.title')}
              </StrongText>
              <ul>
                <li data-testid="terms-of-service-disclaimer-1">
                  {t(
                    'user-legal-terms-of-service.disclaimersOfWarranties.disclaimersOfWarranties1'
                  )}
                </li>
                <li data-testid="terms-of-service-disclaimer2">
                  {t(
                    'user-legal-terms-of-service.disclaimersOfWarranties.disclaimersOfWarranties2'
                  )}
                </li>
                {
                  <li>
                    <span data-testid="terms-of-service-disclaimer3">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.disclaimersOfWarranties3'
                      )}
                    </span>
                    <br />
                    <br />
                    <StrongText data-testid="terms-of-service-australia-title">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.australiaTitle'
                      )}
                    </StrongText>
                    <span data-testid="terms-of-service-in-australia">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia'
                      )}
                    </span>
                    <br />
                    <span data-testid="terms-of-service-australia1">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia1.1'
                      )}
                    </span>
                    <ul>
                      <li data-testid="terms-of-service-australia2">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia1.1(1)'
                        )}
                      </li>
                      <li data-testid="terms-of-service-australia3">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia1.1(2)'
                        )}
                      </li>
                      <li data-testid="terms-of-service-australia4">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia1.1(3)'
                        )}
                      </li>
                    </ul>
                    <br />
                    <span data-testid="terms-of-service-australia5">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia2'
                      )}
                    </span>
                    <br />
                    <span data-testid="terms-of-service-australia6">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.inAustralia3'
                      )}
                    </span>
                    <br />
                    <span data-testid="terms-of-service-contact-licensor">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.address.pleaseContactLicensor'
                      )}
                    </span>
                    <AddressBlock>
                      <StrongText data-testid="terms-of-service-address">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.address.line1'
                        )}
                      </StrongText>
                      <br />
                      <span data-testid="terms-of-service-address-line">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.address.line2'
                        )}
                      </span>
                      <br />
                      <span data-testid="terms-of-service-address-line1">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.address.line3'
                        )}
                      </span>
                      <br />
                      <span data-testid="terms-of-service-address-line2">
                        {t(
                          'user-legal-terms-of-service.disclaimersOfWarranties.Australia.address.line4'
                        )}
                      </span>
                    </AddressBlock>
                    <span data-testid="terms-of-service-for-support">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.Australia.forSupport'
                      )}
                    </span>
                    <br />
                    <br />
                    <StrongText data-testid="terms-of-service-newzealand-title">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.newZealandTitle'
                      )}
                    </StrongText>
                    <span data-testid="terms-of-service-in-newzealand">
                      {t(
                        'user-legal-terms-of-service.disclaimersOfWarranties.inNewZealand'
                      )}
                    </span>
                  </li>
                }
              </ul>
            </li>
            <li data-testid="terms-of-service-list-11">
              <StrongText data-testid="terms-of-service-limitation-title">
                {t('user-legal-terms-of-service.limitationOfLiability.title')}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li data-testid="terms-of-service-limitation1">
                  {t(
                    'user-legal-terms-of-service.limitationOfLiability.limitation1'
                  )}
                </li>
                <li data-testid="terms-of-service-limitation2">
                  {t(
                    'user-legal-terms-of-service.limitationOfLiability.limitation2'
                  )}
                </li>
                <li data-testid="terms-of-service-limitation3">
                  {t(
                    'user-legal-terms-of-service.limitationOfLiability.limitation3'
                  )}
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-12">
              <StrongText data-testid="terms-of-service-termination">
                {t('user-legal-terms-of-service.termAndTermination.title')}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li>
                  <StrongText data-testid="terms-of-service-termtitle">
                    {t(
                      'user-legal-terms-of-service.termAndTermination.termTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-term">
                    {t('user-legal-terms-of-service.termAndTermination.term')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-term-title">
                    {t(
                      'user-legal-terms-of-service.termAndTermination.terminationTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-termination">
                    {t(
                      'user-legal-terms-of-service.termAndTermination.termination'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-survival-title">
                    {t(
                      'user-legal-terms-of-service.termAndTermination.survivalTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-survival">
                    {t(
                      'user-legal-terms-of-service.termAndTermination.survival'
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li data-testid="terms-of-service-list-13">
              <StrongText data-testid="terms-of-service-governing-law">
                {t(
                  'user-legal-terms-of-service.governingLawAndJurisdiction.title'
                )}
              </StrongText>
              <br />
              <span data-testid="terms-of-service-description">
                {t(
                  'user-legal-terms-of-service.governingLawAndJurisdiction.description'
                )}
              </span>
            </li>
            <li data-testid="terms-of-service-list-14">
              <StrongText data-testid="terms-of-service-general-title">
                {t('user-legal-terms-of-service.general.title')}
              </StrongText>
              <ul data-testid="terms-of-service-ul">
                <li>
                  <StrongText data-testid="terms-of-service-export-title">
                    {t(
                      'user-legal-terms-of-service.general.exportRestrictionsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-export">
                    {t(
                      'user-legal-terms-of-service.general.exportRestrictions'
                    )}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-change-terms-title">
                    {t(
                      'user-legal-terms-of-service.general.changesToTermsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-change-terms">
                    {t('user-legal-terms-of-service.general.changesToTerms')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-severability-title">
                    {t('user-legal-terms-of-service.general.severabilityTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-severability">
                    {t('user-legal-terms-of-service.general.severability')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-waiver-title">
                    {t('user-legal-terms-of-service.general.waiverTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-waiver">
                    {t('user-legal-terms-of-service.general.waiver')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-relationship-title">
                    {t('user-legal-terms-of-service.general.relationshipTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-relationship">
                    {t('user-legal-terms-of-service.general.relationship')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-aggrement-title">
                    {t(
                      'user-legal-terms-of-service.general.entireAgreementTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-agreement">
                    {t('user-legal-terms-of-service.general.entireAgreement')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-assignment-title">
                    {t('user-legal-terms-of-service.general.assignmentTitle')}
                  </StrongText>
                  <span data-testid="terms-of-service-assignment">
                    {t('user-legal-terms-of-service.general.assignment')}
                  </span>
                </li>
                <li>
                  <StrongText data-testid="terms-of-service-third-party-title">
                    {t(
                      'user-legal-terms-of-service.general.noThirdPartyRightsTitle'
                    )}
                  </StrongText>
                  <span data-testid="terms-of-service-third-party">
                    {t(
                      'user-legal-terms-of-service.general.noThirdPartyRights'
                    )}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Container>
      <FooterStyles>
        <Footer
          mainContent={{
            leadingArea: (
              <CopyRightsFooter>
                <span>
                  {t(
                    'user-legal-terms-of-service.copyRights',
                    '© Copyright 2023 HP Development Company, L.P.'
                  )}
                </span>
              </CopyRightsFooter>
            ),
            trailingArea: (
              <LanguageSelector>
                <LanguageSelectorMfe />
              </LanguageSelector>
            )
          }}
        />
      </FooterStyles>
    </>
  )
}

export default LegalTermsOfService
