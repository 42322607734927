import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import tokens from '@veneer/tokens'

export const Container = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${tokens.colorGray8};
  padding: ${tokens.space8};
  font-family: ${tokens.fontTextRegular} !important;
  font-size: ${tokens.fontSize3} !important;
  margin-bottom: 100px;
  direction: ${({ direction }) => direction};
  h1 {
    font-family: ${tokens.fontTextRegular} !important;
    margin: 0;
    font-style: italic;
    font-size: ${tokens.fontSize6} !important;
    line-height: ${tokens.lineHeight8} !important;
  }
  h4 {
    font-family: ${tokens.fontTextRegular} !important;
    margin: 0;
    font-style: italic;
    font-size: ${tokens.fontSize3} !important;
    line-height: ${tokens.lineHeight2} !important;
  }
  h3 {
    font-family: ${tokens.fontTextRegular} !important;
    margin: ${tokens.space4} 0;
    font-size: ${tokens.fontSize4} !important;
    line-height: ${tokens.lineHeight1} !important;
  }
  a {
    margin: 0 ${tokens.space1};
    font-weight: 600;
    text-decoration: none;
    color: ${tokens.colorHpBlue6};
  }
  .italicText {
    font-style: italic;
  }
  ul {
    counter-reset: outItem;
    list-style: none;
    margin: 0;
    font-size: ${tokens.fontSize2} !important;
    padding: revert;
  }
  ul > li {
    margin: ${tokens.space3} 0;
    counter-reset: nestedItem;
  }
  ul > li:before {
    content: counters(outItem, '.') '. ';
    counter-increment: outItem;
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`
export const Paragraph = styled.p`
  margin: ${tokens.space4} 0;
`
export const StrongText = styled.span`
  font-weight: 600;
  margin: 0 ${tokens.space1};
`
export const CopyRightsFooter = styled.span`
  font-size: 14px;
`
export const AddressBlock = styled.div`
  margin: ${tokens.space8};
`
export const LanguageSelector = styled.div`
  .label {
    font-family: ${tokens.fontTextRegular} !important;
  }
  width: 232px;
  border-radius: 0px;
`
export const FooterStyles = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  footer > div:first-child {
    background-color: #f8f8f8 !important;
    padding: 15px 20px !important;
  }
`
