import React, { useEffect, useState } from 'react'
import LegalTermsOfService from '../src/components/LegalTermsOfService'
import { ShellProps } from '../src/types/shell'
import resources from '../src/assets/locale'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { RootProvider } from '../src/contexts/Root'
import { TranslatorFunctionType } from '../src/types/localization'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type LegalTermsOfServiceProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  ...props
}: MfePropsType & LegalTermsOfServiceProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const interfaces = window.Shell.v1

  // You can find the "stack" information by props
  // const stack = props.stack.
  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
  }, [interfaces])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  return (
    <RootProvider {...props}>
      <section id={projectNames.packageJsonName}>
        <LegalTermsOfService {...{ ...props, ...interfaces, t }} />
      </section>
    </RootProvider>
  )
}
